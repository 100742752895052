import React, { useEffect, useState } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import "../../styles/partner-icons.css"

const PartnerIcons = (props) => {
  const [logos, setLogos] = useState([]);

  const [fadeProp, setFadeProp] = useState({
    fade: 'fade-in',
});

useEffect(() => {
  const timer = setInterval(() => {
    if (fadeProp.fade === 'fade-in') {
      setFadeProp({
          fade: 'fade-out'
      })
  } else {
      setFadeProp({
          fade: 'fade-in'
      })
  }
  }, 2500);
  return () => clearInterval(timer);

},[fadeProp] );

  useEffect(() => {
    const timer = setInterval(() => {
      let newLogos = props.content.icons.sort(() => Math.random() - 0.5)
      setLogos(newLogos);
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  function setLogosUp() {
    setLogos(props.content.icons.sort(() => Math.random() - 0.5))
  }

  function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
  }

  return (
    <section className="partner-icons relative bg-black">
      <div className="wrapper relative">
        <header className="wrapper wrapper-md">
            <div className="quote-line quote-line-md" data-sal="fade-in"></div>
            <div data-sal="slide-right" data-sal-delay="0" className="font-16 quote-line-title quote-line-30-percent">{props.content.headline}</div>
        </header>
        {props.content.icons &&
          <div className="wrapper-md align-items-center justify-content-between margin-top--80 d-flex flex-wrap padding-bottom-216 icon-wrap">
          {props.content.icons.slice(0, 6).map((icons, index) => (
            <div className="col-6 col-md-4 col-lg-2 icon align-center " data-sal="slide-up" data-sal-delay="200" id={"sec-" + index}>
            {icons.icon &&
              <GatsbyImage image={icons.icon.localFile.childImageSharp.gatsbyImageData}  alt={icons.icon.altText} />
            }
            </div>
            ))}
          </div>
        }
      </div>
      </section>
  )

}

export default PartnerIcons

import * as React from "react"
import { useState } from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import { Accordion, Card } from "react-bootstrap"

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {EffectFade ,Autoplay} from 'swiper';

import "../../styles/product-details.css"

import "swiper/css/effect-fade"
SwiperCore.use([EffectFade, Autoplay]);

const ProductDetails = (props) => {
  console.log("Rendering TextInput..." + props.index)

        const [isVisible, setIsVisible] = useState(0);

        function toggleVisible(index) {
          if(isVisible === index) {
            setIsVisible(-1);
          } else {
            setIsVisible(index);
          }
        };
        
        const [swiper, setSwiper] = useState(null);


        function toSlide(num) {
          swiper.slideTo(num)
        }

  return (
    <section className={`sec-product-details bg-white-true block-vw
    ${props.content.decreaseTopPadding ? 'block-padding-top-half' : ''}
    `} id={"sec-" + props.index}>
      <header className="wrapper wrapper-md">
        <div data-sal="slide-right" data-sal-delay="0" className="font-88 offset-md-1 font-w-300">{props.content.productHeading}</div>
      </header>
      <div className="wrapper wrapper-md margin-top--150">
        <div className="d-md-flex row-40">
            <div className="col-md-5"><div>
            {props.content.productAccordion &&
                <Swiper
                slidesPerView={1}
                loop={false}
                onSwiper={setSwiper}
                effect={'fade'}
                fadeEffect={{ crossFade: true }}
                autoplay={{
                  "delay": 5000
                }} 
                // slideChange={() => toggleVisible(swiper)}
                onSlideChange={(swiperCore) => {
                  const {
                    activeIndex,
                    snapIndex,
                    previousIndex,
                    realIndex,
                  } = swiperCore;
                  // console.log({ activeIndex, snapIndex, previousIndex, realIndex });
                  toggleVisible(activeIndex)
                }}
              >
                {props.content.productAccordion.map((productAcc, index) => (
                  <SwiperSlide key={index}  onClick={() => toggleVisible(index)} >
                    <div className="relative">
                    {productAcc.prodImage
                      &&
                      <GatsbyImage image={productAcc.prodImage.localFile.childImageSharp.gatsbyImageData} width="536" height="640" alt={productAcc.prodImage.altText} />
                    }
                    </div>
                  </SwiperSlide>

                ))}
                </Swiper>
              } 
                </div>
              </div>
              <div class="col-md-6 offset-md-1">
              <h3 className="headline-5 font-w-300 margin-top--24">{props.content.accordionHeading}</h3>
              {props.content.productAccordion &&
                <Accordion className="margin-top--72">
                {props.content.productAccordion.map((productAcc, index) => (
                <Card key={index} className={isVisible === index ? 'card-active' : ''}>
                    <Accordion.Toggle as={Card.Header} eventKey={index + 1}  onClick={() => toSlide(index)} >
                        {productAcc.accordionTitle}
                    </Accordion.Toggle>

                    <Accordion.Collapse className={isVisible === index ? 'collapse show' : ''} eventKey={index + 1}>
                        <Card.Body dangerouslySetInnerHTML={{ __html: productAcc.accordionContent }}></Card.Body>
                    </Accordion.Collapse>
                </Card>
                ))}
                </Accordion>
              }
            </div>

        </div>
      </div>
    </section>
  )
}

export default ProductDetails

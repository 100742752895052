import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Tabs, Tab } from "react-bootstrap"

import "../../styles/tabs.css"

const ContentTabs = (props) => {

  function tabListItems(items) {
    if(items) {
      return(
        <div className="tab-list-items margin-top--32 d-flex row">
          {items.map((item) => (
            <div class="col-md-6 tab-list-item-wrap">
              <div className="tab-list-item color-white-50">{item.listItem}</div>
            </div>
          ))}
        </div>
      )
    }
  }

  function tabCta(cta) {
    if(cta) {
      return(
        <div className="margin-top--32">
          <a href={cta.url} className="btn btn-white" target={cta.target}>{cta.title}</a>
        </div>
      )
    }
  }

  return (
    <section className="sec-tabs bg-black"  id={'sec-' + props.index}>
      {props.content.featuredImage &&
        <div className="spacer-sec d-flex justify-content-end"><GatsbyImage
          image={props.content.featuredImage.localFile.childImageSharp.gatsbyImageData}
          alt={props.content.featuredImage.altText}
          data-sal="slide-left"
        /></div>
      }
      <div className="block-vw">
        <header data-sal="slide-up">
          <div className="wrapper wrapper-md">
            <h3 className="headline-2 font-w-300" dangerouslySetInnerHTML={{ __html: props.content.sectionHeading }}></h3>
          </div>
        </header>


        <Tabs defaultActiveKey="key-0" id="uncontrolled-tab-example" className="mb-3 new-scrollbar-x" data-sal="fade">
          {props.content.tabs &&
            props.content.tabs.map((tab, index) => (
              <Tab eventKey={"key-" + index} title={tab.tabName}>
                <div className="wrapper wrapper-md">
                  <div className="d-lg-flex">
                    <div className="col-lg-6 offset-lg-1 order-lg-1">
                    {tab.tabImage &&
                      <GatsbyImage
                        image={tab.tabImage.localFile.childImageSharp.gatsbyImageData}
                        alt={tab.tabImage.altText}
                      />
                    }
                    </div>
                    <div className="col-lg-5 margin-top-50-lg">
                      <h3 className="font-36 font-w-300">{tab.heading}</h3>
                      <div className="margin-top--16 font-28 color-white-50">{tab.shortDescription}</div>
                      {tabListItems(tab.listItems)}
                      {tabCta(tab.callToAction)}
                    </div>
                  </div>
                </div>
              </Tab>
          ))}
        </Tabs>
      </div>

    </section>
  )
}

export default ContentTabs

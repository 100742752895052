import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/resource-featured.css"

const ResourceFeatured = (props) => {

  function linkUrl(resource) {
    if(resource.categories.nodes) {
      let label = resource.categories.nodes.slice(-1).pop().seo.breadcrumbs.slice(-1).pop().text
      if(label === 'Whitepaper') {
        if(resource.resources.pdfShareLink) {
          return resource.resources.pdfShareLink.localFile.publicURL
        }
      }
    }

    return resource.uri
  }

  function linkLabel(resource) {
    if(resource.categories.nodes) {
      let label = resource.categories.nodes.slice(-1).pop().seo.breadcrumbs.slice(-1).pop().text
      if(label === 'Blog')  {
        return <span className="link-arrow color-white">View Blog</span>
      } else {
        return <span className="link-arrow color-white">View {label}</span>
      }
    } else {
      return <span className="link-arrow color-white">View Blog</span>
    }

  }

  function linkTarget(resource) {
    if(resource.categories.nodes) {
      let label = resource.categories.nodes.slice(-1).pop().seo.breadcrumbs.slice(-1).pop().text
      if(label === 'Whitepaper') {
        return '_blank';
      }
    }

    return '';
  }

  return (
    <section className="bg-black-true block-vw" id={"sec-" + props.index}>
      <div className="wrapper wrapper-md">
        <div className="d-md-flex">
          <div className="col-md-4 offset-md-1 font-16">
            <div className="quote-line color-white quote-line-offset" data-sal="fade-in"></div>
            <div className="quote-line-30-percent" data-sal="slide-right" data-sal-delay="0">Featured</div>
          </div>
          <div className="col-md-7 margin-top-50-md" data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="300"
          data-sal-easing="ease">
          {props.content.resource &&
            props.content.resource.map((resource) => (
              <div className="margin-top-25-md">
                <a href={linkUrl(resource)} target={linkTarget(resource)} className="color-white">
                {resource.resources.featuredImage &&
                  <GatsbyImage image={resource.resources.featuredImage.localFile.childImageSharp.gatsbyImageData}  alt="Featured Clients" />
                }

                <div className="featured-resource-content">
                  <h3
                  dangerouslySetInnerHTML={{ __html: resource.title }}
                  className="margin-top--24 font-36 font-w-300 color-white"
                  >
                  </h3>
                  <div className="margin-top--8 font-20 color-white-50">{resource.resources.excerpt}</div>
                  <div className="margin-top--24 color-white">{linkLabel(resource)}</div>
                </div>
                </a>
              </div>
          ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResourceFeatured

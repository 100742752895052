import * as React from "react"
import GravityForm from "react-gravity-form";
import "../../styles/forms.css"

const FormSupport = (props) => {
  return (
    <section className="sec-form bg-black block-vw" id={"sec-" + props.index}>
      <div className="wrapper wrapper-md">
        <header>
        <div className="col-12 col-sm-9 col-lg-5" data-sal="slide-up" >
          <h3 className="headline-2 font-w-300" dangerouslySetInnerHTML={{ __html: props.content.heading }}></h3>
        </div>
        </header>
        <div className="d-md-flex margin-top--96" data-sal="slide-up" >
          <div className="col-md-5">
            <div>
            {props.content.listItems &&
              props.content.listItems.map((item) => (
                  <div className="contact-list-item">{item.listItem}</div>
              ))
            }
            </div>
            <div className="contact-info">
              <div className="color-white-50" dangerouslySetInnerHTML={{ __html: props.content.supportInfo }}></div>
              <div className="margin-top--8">
                <a href={"mailto:" + props.content.supportEmail} className="link-arrow color-white" >{props.content.supportEmail}</a>
              </div>
            </div>
          </div>
          <div className="col-md-6 offset-md-1 margin-top-50-md main-contact" data-sal="slide-up" data-sal-delay="450" >
            <GravityForm
                    backendUrl="https://wp.nanolumens.com/wp-json/glamrock/v1/gf/forms"
                    formID={props.content.formId}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FormSupport

import React,{ useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import MasonryModal from "../masonryModal"

const MasonryGallery = (props) => {
//  load more
  // Array of all projects
  const allProjects = (props.content.projects) ? props.content.projects : []
  // console.log(allProjects);
  // State for the list
  const [list, setList] = useState([...allProjects.slice(0, 5)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allProjects.length > 5)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allProjects.length
      const nextResults = isMore
        ? allProjects.slice(currentLength, currentLength + 5)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allProjects.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line
  const [modalIsOpen, setIsOpen] = React.useState(false);

function openModal() {
  setIsOpen(true);
 }

 function afterOpenModal() {
  console.log('after open');
 }

 function closeModal() {
  setIsOpen(false);
 }

  return (
    <section className="sec-masonry-gallery sec-markets bg-black block-vw" id={"sec-" + props.index}>
        <header class="wrapper wrapper-md">
            <div class="quote-line quote-line-md sal-animate" data-sal="fade-in"></div>
            <div data-sal="slide-right" data-sal-delay="0" class="font-16 quote-line-title sal-animate">{props.content.sectionHeading }</div>
        </header>

      {list &&
        <div className="wrapper wrapper-lg margin-top--120">
          <div className="d-md-flex justify-content-between row">

          {list.map((project, gridcount) => (
              <div className= "col-md-auto padding-bottom-160 cursor mas-img"  id={'gal-sec-' + gridcount}>
              <MasonryModal project= { project}/>
              </div>
          ))}
          </div>
        </div>
      }

      <div className="col-md-12 align-center">
        {hasMore ? (
        <button className="btn btn-white" onClick={handleLoadMore}>View More</button>
        ) : (
          <p></p>
        )}
        </div>
    </section>
  )
}

export default MasonryGallery

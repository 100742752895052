import React, { useState, useEffect } from "react"

import Modal from 'react-modal'
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Accordion, Card } from "react-bootstrap"

// import Swiper core and required modules
import SwiperCore, {
  Navigation
} from 'swiper';


import 'swiper/css';
import "swiper/css/navigation"
import "../styles/modal.css";
import "../styles/swiper-prev-next.css"
import "../styles/masonry-gallery.css"
SwiperCore.use([Navigation]);


const MasonryModal = (props) => {

const [modalIsOpen, setIsOpen] = React.useState(false);

function openModal() {
 setIsOpen(true);
}

function afterOpenModal() {
 console.log('after open');
}

function closeModal() {
 setIsOpen(false);
}

Modal.setAppElement('#___gatsby');
const [isVisible, setIsVisible] = useState(0);
function toggleVisible(index) {
    if(isVisible === index) {
      setIsVisible(-1);
    } else {
      setIsVisible(index);
    }
  };

  return (
    <section >

            <div>
            <div className="img-wrap" onClick={() => openModal()}>
            {props.project.acfProjects.featuredImage &&
              <figure class="project-thumb-masonry ">
              <GatsbyImage
                image={props.project.acfProjects.featuredImage.localFile.childImageSharp.gatsbyImageData}
                alt={props.project.acfProjects.featuredImage.altText}
                />
              </figure>
              }


              <div className="margin-top--24 ">
                <h3 className="font-20">{props.project.title}</h3>
                <div className="font-20 color-white-50">{props.project.acfProjects.location}</div>
              </div>
            </div>


            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              className="Modal d-md-flex wrapper wrapper-md"
              overlayClassName="Overlay new-scrollbar-y d-flex align-items-center justify-content-center"
              contentLabel="Example Modal"
            >
              <div className="modal-close" onClick={closeModal}></div>
              <div className="col-md-7 project-gallery masonry-gallery">
              {props.project.acfProjects.gallery &&
                 <Swiper
                   navigation={{
                       prevEl: '.swiper-prev-custom',
                       nextEl: '.swiper-next-custom',
                     }}
                   slidesPerView={1}
                   loop={false}
                   >

                   {props.project.acfProjects.gallery.map((gallery, index) => (
                     <SwiperSlide key={index}>
                       <div className="relative">
                       {gallery.image
                        &&
                         <GatsbyImage image={gallery.image.localFile.childImageSharp.gatsbyImageData} alt={props.title} />
                       }
                       </div>
                     </SwiperSlide>
                   ))}

                   {props.project.acfProjects.gallery.length > 1 &&
                     <div className="swiper-custom-wrapper d-flex">
                       <div className="swiper-prev-custom"></div>
                       <div className="swiper-next-custom"></div>
                     </div>
                  }
                </Swiper>
              }
              </div>
              <div className="col-md-4 offset-md-1 project-gallery-details margin-top-50-md">
                <h4 class="font-20 color-white">{props.project.title}</h4>
                <div className="color-white-50">{props.project.acfProjects.location}</div>
                {props.project.acfProjects.projectDetails &&
                  <div className="margin-top--24 new-scrollbar-y accord-modal-side">
                    <Accordion className="margin-top--24 modal-accordion">
                    {props.project.acfProjects.projectDetails.map((detail, index) => (
                        <Card key={index} className={isVisible === index ? 'card-active' : ''}>
                            <Accordion.Toggle as={Card.Header} eventKey={index} onClick={() => toggleVisible(index)} >
                                {detail.sectionHeading}
                            </Accordion.Toggle>

                            <Accordion.Collapse className={isVisible === index ? 'collapse show' : ''} eventKey={index}>
                                <Card.Body dangerouslySetInnerHTML={{ __html: detail.details }}></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                    </Accordion>
                </div>
                }
              </div>
            </Modal>
          </div>
    </section>
  )
}

export default MasonryModal

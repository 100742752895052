import React, { useState } from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import ProductsOptionFilter from "./productOptionFilter"
import ProductsApplicationFilter from "./productsApplicationFilter"
import PixelRange from "./pixelRange"

import "../../styles/product-filters.css"

const ProductsFilter = (props) => {

  const [options, setOptions] = React.useState([]);
  const [applications, setApplications] = React.useState([]);
  const [pixels, setPixels] = React.useState([]);

  function resetFilters() {
    setOptions([]);
    setApplications([]);
    setPixels([]);
  }

   function handleChange(newValue) {
     if(options.includes(newValue)) {
       let filterOptions = options.filter(option => option !== newValue);
       setOptions(filterOptions);
     } else {
       const updatedOptionsArray = [...options, newValue];
       setOptions(updatedOptionsArray);
     }
   }

   function handleApplicationChange(newValue) {
     if(applications.includes(newValue)) {
       let filterApplications = applications.filter(application => application !== newValue);
       setApplications(filterApplications);
     } else {
       const updatedApplicationsArray = [...applications, newValue];
       setApplications(updatedApplicationsArray);
     }
   }

   function handleRangeChange(newValue) {
     setPixels([newValue[0], newValue[1]]);
   }

   function hasProductOptions(values) {
     if(options.length === 0) {
       return true;
     }

     let containsAllOptions = options.every(i => values.includes(i));

     if(containsAllOptions) {
       return true;
     } else {
       return false;
     }

     // let hasOptions = false;
     // values.forEach(function(value){
     //   if(options.includes(value)) {
     //     hasOptions = true;
     //   }
     // })
     //
     // return hasOptions;
   }

   function hasProductApplications(values) {

     if(applications.length === 0) {
       return true;
     }

     let containsAllApplications = applications.every(i => values.includes(i));

     if(containsAllApplications) {
       return true;
     } else {
       return false;
     }
   }

   function hasPixelRange(bottom, top) {
     if(pixels.length === 0) {
       return true;
     }
     if( (bottom <= pixels[0] || bottom <= pixels[1]) && (pixels[1] <= top || pixels[0] <= top)) {
       return true;
     } else {
       return false;
     }
   }


   function productInfo(product, className) {
     return (
       <div className={"col-md-4 gap-25 margin-top-50-md margin-bottom--48 product-filter-item " + className }>
        {product.featuredImage
          &&
           <GatsbyImage
            image={product.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
            alt={product.featuredImage.altText}
            className="product-img"
            />
        }
       <div className="prod-title font-28 margin-top--24 font-w-300" >{product.title}</div>
       <div className="prod-desc  font-16 margin-top--8 margin-bottom--8" >{product.products.shortDescription}</div>
       {product.products.filterLink &&
       <div className="prod-link"><a href={product.products.filterLink.url} className="font-15 link-underline">{product.products.filterLink.title}</a></div>
        }
       </div>
     )
   }

  const data = useStaticQuery(graphql`
    query filterProductQuery {
        allWpProduct {
        nodes {
          title
          series {
            nodes {
              name
              slug
            }
          }
          applications {
            nodes {
              name
              slug
            }
          }
          options {
            nodes {
              slug
              name
            }
          }
          products {
            shortDescription
            pixelRange
            pixelRangeBottom
            filterLink {
              title
              url
              target
            }
          }
          featuredImage {
            node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 144, width: 220)
              }
            }
          }
        }
        }
      }
    }
  `)



  return (

    <div className="filters-wrap block-vw" id={"sec-" + props.index}>
    <header className="wrapper wrapper-lg">
        <div className="quote-line quote-line-lg color-black" data-sal="fade-in"></div>
        <div data-sal="slide-right" data-sal-delay="300" className="font-16 quote-line-30-percent">Product Finder</div>
        <div className="product-finder-header margin-top--64" >
            <h2 className="font-48 font-w-300">Discover the possibilities of edge-to-edge brilliance.</h2>
        </div>
    </header>

      <div className="wrapper wrapper-md d-flex margin-top--120" >
      <div className="filters-sidebar">
          <div><button class="btn filter-btn" onClick={() => resetFilters()}>Reset Filters</button></div>
          <PixelRange onRangeChange={handleRangeChange} />
          <ProductsApplicationFilter actives={applications} onApplicationChange={handleApplicationChange} />
          <ProductsOptionFilter actives={options} onChange={handleChange} />
      </div>
      <div className="w-100">
        <div className="row">
          {data.allWpProduct.nodes
            &&
            data.allWpProduct.nodes.map((product) => {
            const productOptions = product.options.nodes.map(option => option.name)
            const productApplications = product.applications.nodes.map(application => application.name)
            let pixelRangeTop = product.products.pixelRange;
            let pixelRangeBottom = product.products.pixelRangeBottom;

            if (options.length === 0 & applications.length === 0 & pixels.length === 0 ) {
              return productInfo(product, 'no-blur')
            } else {

              if(hasProductApplications(productApplications) & hasProductOptions(productOptions) & hasPixelRange(pixelRangeBottom, pixelRangeTop)) {
                return productInfo(product, 'no-blur')
              } else {
                return productInfo(product, 'has-blur')
              }

            }
          })}
          </div>
        </div>
      </div>
    </div>
  )
}


export default ProductsFilter

import * as React from "react"
import { GatsbyImage} from "gatsby-plugin-image"
import { ComparisonSlider } from 'react-comparison-slider';
import '../../styles/patent-grid.css'

 const PatentGrid = (props) => {
  return (
    <section className="sec-patents-grid bg-black">
      <div className="wrapper wrapper-md padding-bottom-112 padding-top-112">
          <div className="col-md-6 patent-top">
              <h2 className="font-64-lh-68">{props.content.heading}</h2>
              <div className="font-20 color-white-50">{props.content.subHeading} </div>
          </div>
          {props.content.beforeAndAfter && 
            <div className=" d-flex row  row-70">
                {props.content.beforeAndAfter.map((images) => (
                  <div className="col-md-6 gap-70" >
                  <ComparisonSlider
                      defaultValue={50}
                      itemOne={
                        <div className="before-img">
                        {images.beforeImage
                          &&
                          <GatsbyImage
                              image={images.beforeImage.localFile.childImageSharp.gatsbyImageData}
                              alt={images.beforeImage.altText}
                          />
                        }

                        </div>
                        }
                        itemTwo={
                        <div className="after-img">
                          {images.afterImage
                            &&
                            <GatsbyImage
                                image={images.afterImage.localFile.childImageSharp.gatsbyImageData}
                                alt={images.afterImage.altText}
                            />
                          }
                        </div>
                        }
                        handleBefore={<div className="bg-white w-2 bottom-0 h-full"></div>}
                        handleAfter={<div className="bg-white w-2 bottom-0 h-full"></div>}
                        aspectRatio={4 / 3}
                        handle={
                            () => {
                            return (
                              <div  style={{ display: 'grid', placeContent: 'center', height: '100%' }}>
                                <button className="compare-handle"></button>
                              </div>
                            );
                          }
                        }
                    />
                        <div className="font-18 margin-top--24">{images.patentNumber}</div>
                        <div className="font-18 margin-bottom--80">{images.asSeen}</div>
                  </div>
                ))}
            </div>
          }
      </div>
    </section>
  );
};


export default PatentGrid

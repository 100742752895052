import * as React from "react"
import "../../styles/split-content-cta.css"

const SplitContentCta = (props) => {

  return (
    <section className="split-content-cta  relative bg-black">
      <div className="wrapper wrapper-md relative">
        <div className="justify-content-between margin-top--160 d-md-flex">
          <div className="col-md-6" data-sal="slide-up" data-sal-delay="200" >
            <div className="font-64 split-content-left">{props.content.leftHeading}</div>
          </div>
          <div className="col-md-6 split-content-right" data-sal="slide-up" data-sal-delay="200" >
            <div className="font-20 color-white-50">{props.content.rightContent}</div>
          </div>
        </div>
      </div>
      </section>
  )
}

export default SplitContentCta

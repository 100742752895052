import * as React from "react"
import "../../styles/split-content.css"

function button(cta) {
  if(cta) {
    return <a href={cta.url} className="btn btn-small-arrow color-white" target={cta.target}>{cta.title}</a>
  }
}

const SplitContent = (props) => {
  return (
    <section className="split-content  relative bg-black">
      <div className="wrapper wrapper-md relative">
      <div data-sal="slide-right" data-sal-delay="0" className="font-20 split-pre-headline">{props.content.preHeadline}</div>
        <div className="justify-content-between margin-top--40 d-md-flex">
          <div className="col-md-6" data-sal="slide-up" data-sal-delay="200" >
            <div className="font-48 split-content-left">{props.content.leftContent}</div>
            <div class="col-4 margin-top--40">
              {button(props.content.button)}
            </div>
          </div>
          <div className="col-md-6 split-content-right" data-sal="slide-up" data-sal-delay="200" >
            <div className="font-20">{props.content.rightContentBold}</div>
            <div className="font-20 color-white-50">{props.content.rightContent}</div>
          </div>
        </div>
      </div>
      </section>
  )

}

export default SplitContent

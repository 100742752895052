import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ProductsApplicationFilter = (props) => {
  const data = useStaticQuery(graphql`
    query filterApplicationQuery {
        allWpApplication {
        nodes {
          name
          products {
            nodes {
              title
            }
          }
        }
      }
    }
  `)

  function handleApplicationChange(value) {
      // Here, we invoke the callback with the new value
      props.onApplicationChange(value);
  }

  return (
    <div class="d-md-flex flex-wrap integrations-row margin-top--80">
      <div className="w-100">
        <div class="font-16"> Choose application </div>
        <div className="row-6 margin-top--16">
          <div className="d-flex flex-wrap w-100">
          {data.allWpApplication.nodes
            &&
            data.allWpApplication.nodes.map((application) => (
            <div className="col-sm-6 gap-6">
              <button
              className={`btn filter-btn ${
                    props.actives.includes(application.name) ? "btn-active" : ""
                  }`}
                onClick={() => handleApplicationChange(application.name)}
                >
                {application.name}
              </button>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}


export default ProductsApplicationFilter

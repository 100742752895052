
import React from "react"
// import { StaticQuery, graphql } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"
// import Img from "gatsby-image"
// import IntegrationImage from "./integrationImage"
const ProductsOptionFilter = (props) => {
  const data = useStaticQuery(graphql`
    query filterOptionQuery {
        allWpOption {
        nodes {
          name
          products {
            nodes {
              title
            }
          }
        }
      }
    }
  `)

  function handleChange(value) {
      // Here, we invoke the callback with the new value
      props.onChange(value);
  }



  return (
  <div className="d-md-flex flex-wrap 2row integrations-row margin-top--40">
  <div className="w-100">
    <div className="font-16">More options</div>
    <div className="row-6 margin-top--16">
      <div className="d-flex flex-wrap w-100">
      {data.allWpOption.nodes.map((option) => (

        <div className="col-sm-6 gap-6">
        <button
          className={`btn filter-btn ${
                props.actives.includes(option.name) ? "btn-active" : ""
              }`}
          onClick={() => handleChange(option.name)}
          >
          {option.name}
        </button>
        </div>

      ))}
      </div>
    </div>
    </div>
  </div>


  )
}


export default ProductsOptionFilter

import * as React from "react"
import { useState } from 'react';
import { useEffect } from 'react';
import { Accordion, Card } from "react-bootstrap"
import "../../styles/full-width-accordions.css"

const CareersAccordions = (props, index) => {
        const [isVisible, setIsVisible] = useState(0);

        function toggleVisible(index) {
          if(isVisible === index) {
            setIsVisible(-1);
          } else {
            setIsVisible(index);
          }
        };
        const MINUTE_MS = 5000;
    
        useEffect(() => {
          const interval = setInterval(() => {
            // console.log('Logs every 5 seconds');
          }, MINUTE_MS);
          toggleVisible(-1)
          return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }, [])
  return (
    <section className="sec-fw-accordions bg-black block-vw">

      <div className="wrapper wrapper-sm">
        <div className="d-md-flex">
              <div className="col-md-12">
                {props.content.accordion && 
                  <Accordion className="margin-top--72">
                  {props.content.accordion.map((accordion, index) => (
                  <Card key={index} className={isVisible == index ? 'card-active' : ''}>
                      <Accordion.Toggle as={Card.Header} eventKey={index + 1} onClick={() => toggleVisible(index)} >
                          {accordion.accordionHeading}
                      </Accordion.Toggle>

                      <Accordion.Collapse className={isVisible == index ? 'collapse show' : ''} eventKey={index + 1}>
                          <Card.Body dangerouslySetInnerHTML={{ __html: accordion.accordionContent }}></Card.Body>
                      </Accordion.Collapse>
                  </Card>
                  ))}
                  </Accordion>
                }
            </div>

        </div>
      </div>
    </section>
  )
}

export default CareersAccordions

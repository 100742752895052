import React, { useState } from 'react';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import "../../styles/range-slider.css"

class PixelRange extends React.Component {
  state = {
    textValue: null,
    textValue2: null,
    percent: null
  };

  onSlide = (render, handle, value, un, percent) => {
    this.props.onRangeChange([value[0].toFixed(1), value[1].toFixed(1)]);
  };

  render() {
    return (
      <div>
      <div class="font-16 margin-top--32">Select pixel pitch range</div>
      <div className="margin-top--16">
        <Nouislider
          range={{ min: 0, max: 35 }}
          start={[0, 35]}
          step={0.1}
          tooltips={ [{to: function(value){ return value.toFixed(1) + 'mm'; }}, {to: function(value){ return value.toFixed(1) + 'mm'; }}] }
          connect
          onSlide={this.onSlide}
          />
      </div>
      </div>
    );
  }


};

export default PixelRange

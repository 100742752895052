import * as React from "react"

import { GatsbyImage} from "gatsby-plugin-image"


import "../../styles/fullwidth-image.css"


  const FullWidthImage = (props) => {

    return (

        <section className="fw-cta bg-black fw-img">
            <div className="wrapper-lg padding-bottom-240 padding-top-112">
                <div className="image-bottom-cta d-flex align-items-center  bg-off-black justify-content-between">
                    <div class="col-md-12 relative show-hover">
                    {props.content.image &&
                      <div className={props.content.hoverImage ? 'full-hover-primary' : ''}>
                          <GatsbyImage
                              image={props.content.image.localFile.childImageSharp.gatsbyImageData}
                              alt={props.content.image.altText}
                              style={{ width: '100%' }}
                          />
                      </div>
                    }

                    {props.content.hoverImage &&
                      <div className="full-hover-img">
                          <GatsbyImage
                              image={props.content.hoverImage.localFile.childImageSharp.gatsbyImageData}
                              alt={props.content.hoverImage.altText}
                              style={{ width: '100%' }}
                          />
                      </div>
                    }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FullWidthImage

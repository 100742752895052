import * as React from "react"
import "../../styles/left-text-hero.css"

function heroButton(cta) {
  if(cta) {
    return <a href={cta.url} className="btn btn-white" target={cta.target}>{cta.title}</a>
  }
}

const LeftTextHero = (props) => {
  return (
    <section className="left-text-hero d-flex justify-content-start align-items-center relative bg-black">
      <div className="wrapper wrapper-lg">
        <header className="">
            <div className="quote-line quote-line-lg" data-sal="fade-in"></div>
            <div data-sal="slide-right" data-sal-delay="0" className="font-16 quote-line-title quote-line-30-percent">{props.content.sectionHeading}</div>
            <div className="margin-top--80">
            <h1 className="font-88"  data-sal="slide-up" data-sal-delay="400" data-sal-duration="400" data-sal-easing="ease" dangerouslySetInnerHTML={{ __html: props.content.heading }}></h1>
            {props.content.showButton &&
              <div className="margin-top--50 button-wrap" data-sal="slide-up" data-sal-delay="450" data-sal-duration="400" data-sal-easing="ease">
                {heroButton(props.content.button)}
              </div>
            }
            </div>
        </header>
      </div>
      </section>
  )
}

export default LeftTextHero

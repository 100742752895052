import * as React from "react"
import { useState } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Accordion, Card } from "react-bootstrap"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {EffectFade ,Autoplay} from 'swiper'

import "../../styles/accordion.css"
import "../../styles/series-list.css"
import "swiper/css/effect-fade"

SwiperCore.use([EffectFade, Autoplay]);

const SeriesList = (props) => {

  const [isVisible, setIsVisible] = useState(0);

  function toggleVisible(index) {
    if(isVisible === index) {
      setIsVisible(-1);

    } else {
      setIsVisible(index);
    }
  }

  const [swiper, setSwiper] = useState(null);

  function toSlide(num) {
    swiper.slideTo(num)
  }

  const series = useStaticQuery(graphql`
    query {
      allWpSingleSeries(sort: {fields: name, order: ASC}) {
        nodes {
          name
          description
          seriesfields {
            seriesUrl
            seriesImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 640
                    height: 480
                    formats: [AUTO, WEBP]
                    layout: CONSTRAINED
                    outputPixelDensities: [1.5, 2]
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                  )
                }
              }
              altText
            }
          }
        }
      }
    }
  `)

  return (
    <section className="series-list bg-black block-vw--btm" id={"sec-" + props.index}>
      <div className="wrapper wrapper-md">
        <div className="d-lg-flex">
          <div className="col-lg-5">
            {props.content.heading && (
              <header>
                <div className="quote-line quote-line-md" data-sal="fade-in"></div>
                <div data-sal="slide-right" data-sal-delay="0" className="quote-line-30-percent font-16 quote-line-title">{props.content.heading}</div>
              </header>
            )}
            {series?.allWpSingleSeries?.nodes.length && (
              <Accordion className="margin-top--45">
                {series.allWpSingleSeries.nodes.map((series, index) => (
                  <Card key={index}  className={isVisible === index ? 'card-active' : ''}>
                    <Accordion.Toggle as={Card.Header} eventKey={index + 1} data-attr="{index}" onClick={() => toSlide(index)} >
                      {series.name}
                    </Accordion.Toggle>

                    <Accordion.Collapse className={isVisible === index ? 'collapse show ' : ''} eventKey={index + 1}>
                      <>
                        <Card.Body>
                          {series.description}
                        </Card.Body>
                        {series.seriesfields.seriesUrl &&
                          <div className="series-list__item-link">
                            <Link to={series.seriesfields.seriesUrl} className="link-arrow color-white">More</Link>
                          </div>
                        }
                        {series.seriesfields.seriesImage &&
                          <GatsbyImage 
                            image={series.seriesfields.seriesImage.localFile.childImageSharp.gatsbyImageData}
                            className="series-list__item-img"
                            alt={series.seriesfields.seriesImage.altText} />
                        }
                      </>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            )}
            {props.content.cta &&
              <div className="margin-top--72">
                {props.content.cta.target == '_blank' ? (
                  <a href={props.content.cta.url} className="btn btn-white" target={props.content.cta.target}>{props.content.cta.title}</a>
                ) : (
                  <Link to={props.content.cta.url} className="btn btn-white">{props.content.cta.title}</Link>
                )}
              </div>
            }
          </div>
          <div className="col-lg-6 offset-lg-1 hide-md series-list__media">
            <Swiper
              slidesPerView={1}
              loop={false}
              onSwiper={setSwiper}
              effect={'fade'}
              fadeEffect={{ crossFade: true }}
              autoplay={{
                "delay": 5000
              }}
              // slideChange={() => toggleVisible(swiper)}
              onSlideChange={(swiperCore) => {
                const {
                  activeIndex,
                  snapIndex,
                  previousIndex,
                  realIndex,
                } = swiperCore;
                console.log({ activeIndex, snapIndex, previousIndex, realIndex });
                toggleVisible(activeIndex)
              }}
            >
              {series?.allWpSingleSeries?.nodes.length && series.allWpSingleSeries.nodes.map((series, index) => (
                <SwiperSlide key={index}  onClick={() => toggleVisible(index)} >
                  <div className="relative">
                  {series.seriesfields.seriesImage &&
                    <GatsbyImage image={series.seriesfields.seriesImage.localFile.childImageSharp.gatsbyImageData} width="620" height="744" alt={series.seriesfields.seriesImage.altText} />
                  }
                  </div>
                </SwiperSlide>

              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>

          
    // <section className="series-list">
    //   <div className="wrapper">
    //     <div className="series-list__inner">
    //       <h2 className="series-list__heading">{props.heading}</h2>
    //       <div className="series-list__list-wrap">
    //         <div className="series-list__list">
    //           {series.allWpSingleSeries.nodes.map((series, index) => (
    //             <div 
    //               className="series-list__item" 
    //               key={index}
    //               onClick={() => {
    //                 setActiveImage(series.seriesfields.seriesImage.localFile.childImageSharp.gatsbyImageData)
    //                 setActiveAlt(series.seriesfields.seriesImage.altText)
    //               }}>
    //               {series.name}
    //             </div>
    //           ))}
    //         </div>
    //         <div className="series-list__media">
    //           <GatsbyImage
    //             image={activeImage}
    //             alt={activeAlt}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  )
}

export default SeriesList
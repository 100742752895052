import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/team-grid.css"


const TeamGrid = () => {
  const data = useStaticQuery(graphql`
  query team {
    allWpTeamMember (sort: {order: ASC, fields: title}) {
     nodes {
       title
       team_member_fields {
         fieldGroupName
         jobTitle
         shortBio
       }
       featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 312
                height: 304
                transformOptions: {cropFocus: NORTH, fit: COVER}
                formats: [JPG, WEBP]
                quality: 100
                outputPixelDensities: [1.25, 1.5, 2]
              )
            }
          }
        }
      }
     }
   }
  }
`)

let transitionOffset = 0;



    return (
      <section className="team-feed d-flex justify-content-center align-items-center relative">
        <div className="wrapper wrapper-lg d-flex">
        <div className="row " >
        {data.allWpTeamMember.nodes &&
          data.allWpTeamMember.nodes.map((team) => (

            <div className="col-md-4 gap-40" data-sal="slide-up" data-sal-easing="ease">

              <div class="team-feed-img relative">
              {team.featuredImage && (
              <div>
                <GatsbyImage
                  image={team.featuredImage.node.localFile.childImageSharp.gatsbyImageData}

                  alt={team.featuredImage.node.altText}
                  />
              </div>
              )}
              <div className="team-bio-overlay">{team.team_member_fields.shortBio}</div>
              </div>


                <h3 className="font-20">{team.title}</h3>
                <div className="font-20 color-white-50 margin-bottom--80">
                {team.team_member_fields.jobTitle}
                </div>
              </div>




          ))}




         </div>
        </div>
      </section>
    )
  }

  export default TeamGrid

import * as React from "react"
import { useState } from 'react';
import { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Accordion, Card } from "react-bootstrap"
import { GatsbyImage} from "gatsby-plugin-image"

import "../../styles/product-series-accordions.css"

const ProductSeriesAccordions = (props) => {
    const [isVisible, setIsVisible] = useState(0);

        function toggleVisible(index) {
          if(isVisible === index) {
            setIsVisible(-1);

          } else {
            setIsVisible(index);
          }
        }


        const MINUTE_MS = 5000;

      useEffect(() => {
        const interval = setInterval(() => {
          // console.log('Logs every 5 seconds');
        }, MINUTE_MS);
        toggleVisible(-1)
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }, [])



  const data = useStaticQuery(graphql`
  query productsAccordions {
    allWpSingleSeries(sort: {order: ASC, fields: name}) {
    nodes {
      name
      seriesfields {
        accordionImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        installationGuides {
          installFileType
          fieldGroupName
          installFileLabel
          installationGuidesFile {
            link
            publicUrl
          }
        }
        specifications {
          fieldGroupName
          fileType
          specFileLabel
          specFile {
            link
            publicUrl
          }
        }
        marketingResources {
          fieldGroupName
          marketFileType
          marketFileLabel
          marketingFiles {
            link
            publicUrl
          }
        }
        firstColumnHeading
        secondColumnHeading
        thirdColumnHeading
      }
    }
  }
  }
`)




    return (
      <section className="product-series-accordions block-vw d-flex justify-content-center align-items-center relative" id={"sec-" + props.index}>
        <div className="wrapper wrapper-lg">
        <h2 class="font-48">Product resources</h2>
        <Accordion className="margin-top--72" >
        {data.allWpSingleSeries.nodes
          &&
          data.allWpSingleSeries.nodes.map((series, index) => (
            <Card key={index} className={isVisible == index ? 'card-active' : ''}>
                <Accordion.Toggle  as={Card.Header} eventKey={index + 1} onClick={() => toggleVisible(index)} >
                <h3 class="font-28">{series.name}</h3>
                </Accordion.Toggle>
                <Accordion.Collapse  className={isVisible == index ? 'collapse show ' : ''} eventKey={index + 1}>
                  {/*collapse show */}
                <Card.Body>
          <div className="row justify-content-between" >
              <div class="col-md-3">

              <div>
                <figure>
                {series.seriesfields.accordionImage &&
                <GatsbyImage
                  image={series.seriesfields.accordionImage.localFile.childImageSharp.gatsbyImageData}
                  width="248"
                  height="302"
                  objectFit="contain"
                  alt={series.seriesfields.accordionImage.altText}
                  />
                }
                </figure>
              </div>

              </div>
              <div class="col-md-3">
                <h3 className="font-16 color-black">{series.seriesfields.firstColumnHeading}</h3>
                {series.seriesfields.specifications 
                &&
                series.seriesfields.specifications.map((specs) => (

             <div class="dl-inner">

                <span class="file-type">

                <img src={specs.fileType} ></img>
                </span>
                <a href={specs.specFile.publicUrl} target="_blank">
                <div class="file-label font-16">{specs.specFileLabel} </div>
                </a>
            </div>

              ))}
              </div>
              <div class="col-md-3">
              <h3 className="font-16 color-black">{series.seriesfields.secondColumnHeading}</h3>
              {series.seriesfields.installationGuides
                &&
                series.seriesfields.installationGuides.map((install) => (
              <div class="dl-inner">
                <span class="file-type">
                <img src={install.installFileType}></img>
                </span>
                <a href={install.installationGuidesFile.publicUrl} target="_blank">
                <div class="file-label font-16">{install.installFileLabel} </div>
                </a>
              </div>
              ))}
               </div>
               <div class="col-md-3">
               <h3 className="font-16 color-black">{series.seriesfields.thirdColumnHeading}</h3>
              {series.seriesfields.marketingResources
                &&
                series.seriesfields.marketingResources.map((market) => (
              <div class="dl-inner">
                <span class="file-type">
                <img src={market.marketFileType} ></img>
                </span>
                <a href={market.marketingFiles.publicUrl} target="_blank">
                <div class="file-label font-16">{market.marketFileLabel} </div>
                </a>
                </div>
              ))}
               </div>

          </div>
          </Card.Body>
          </Accordion.Collapse>
          </Card>
          ))}
         </Accordion>
        </div>
      </section>
    )
  }

  export default ProductSeriesAccordions

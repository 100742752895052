import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
 import Seo from "../components/seo"
import Hero from "../components/modules/hero"
import SkipHero from "../components/modules/skipHero"
import Quote from "../components/modules/quote"
import FeaturedClients from "../components/modules/featuredClients"
import WorkWithUs from "../components/modules/workWithUs"
import SliderMarkets from "../components/modules/sliderMarkets"
import Capabilities from "../components/modules/capabilities"
import Resources from "../components/modules/resources"
import PlanLearn from "../components/modules/planLearn"
import ThreeColumn from "../components/modules/threeColumn"
import Markets from "../components/modules/markets"
import ThreeColumnList from "../components/modules/threeColumnList"
import ProductFeed from "../components/products/productFeed"
import PageMenu from "../components/modules/pageMenu"
import FaqAccordion from "../components/modules/faqAccordion"
import ProductDetails from "../components/modules/productDetails"
import SeriesBenefits from "../components/modules/seriesBenefits"
import SubNavigation from "../components/modules/subNavigation"
import ContentWithLogos from "../components/modules/contentWithLogos"
import ContentBenefits from "../components/modules/contentBenefits"
import Stats from "../components/modules/stats"
import ProjectGallery from "../components/modules/projectGallery"
import QuoteLarge from "../components/modules/quoteLarge"
import FormRegistration from "../components/modules/formRegistration"
import FormContact from "../components/modules/formContact"
import SpecTables from "../components/modules/specTables"
import ProductSeriesAccordions from "../components/products/productSeriesAccordions"
import ContentTabs from "../components/modules/contentTabs"
import AllResources from "../components/modules/allResources"
import ResourceFeatured from "../components/modules/resourceFeatured"
import ProductsFilter from "../components/products/filters"
import LeftTextHero from "../components/modules/leftTextHero"
import MasonryImages from "../components/modules/masonryImages"
import PartnerIcons from "../components/modules/partnerIcons"
import SplitContent from "../components/modules/splitContent"
import TeamGrid from "../components/modules/teamGrid"
import BottomCta from "../components/modules/bottomCta"
import FullWidthImage from "../components/modules/fullWidthImage"
import CareersAccordions from "../components/modules/careerAccordions"
import FullWidthText from "../components/modules/fullWidthTextContent"
import WistiaChannelModule from "../components/modules/WistiaChannelModule"
import PatentGrid from "../components/modules/patentGrid"
import TwoColumnContent from "../components/modules/twoColumnContent"
import MasonryGallery from "../components/modules/masonryGallery"
import SplitContentCta from "../components/modules/splitContentCta"
import FormSupport from "../components/modules/formSupport"
import Instructions from "../components/modules/instructions"
import SeriesList from "../components/modules/seriesList"
import MediaFeature from "../components/modules/mediaFeature"
import SalesforceRma from "../components/modules/salesforceRma"
import SalesforceSupport from "../components/modules/salesforceSupport"
import Patents from "../components/modules/patents"

export const query = graphql`
  query($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      slug
      seo {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterDescription
        twitterImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
      }
      layouts {
        layouts {
          ... on WpPage_Layouts_Layouts_Hero {
            fieldGroupName
            leadingImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 120
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    layout: CONSTRAINED
                    outputPixelDensities: [1, 2]
                    quality: 80
                  )
                }
              }
              altText
            }
            preHeading
            heading
            subHeading
            headingFontSize
            showMarketSelector
            showVideo
            videoId
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000, height: 750, placeholder: BLURRED)
                }
              }
            }
            mobileBackgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 750, placeholder: BLURRED)
                }
              }
            }
            showProductFeatures
            productFeatures{
              feature
            }
            showButtons
            buttons{
              cta {
                target
                title
                url
              }
            }
            showPartnerLogos
            partnerLogos {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      layout: CONSTRAINED
                      outputPixelDensities: [1, 2]
                      quality: 80
                    )
                  }
                }
                altText
              }
            }
          }
          ... on WpPage_Layouts_Layouts_SkipHero {
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_Quote {
           fieldGroupName
           heading
           sectionHeading
         }
         ... on WpPage_Layouts_Layouts_FaqAccordion {
            fieldGroupName
            sectionHeading
            sectionImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            faqs {
              ... on WpFAQ {
                id
                title
                acfFAQ {
                  answer
                }
              }
            }
          }
         ... on WpPage_Layouts_Layouts_FeaturedClients {
           ctaHeading
           fieldGroupName
           clientLogos {
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
           ctaBackgroundImage {
             localFile {
               childImageSharp {
                 gatsbyImageData
               }
             }
           }
           sectionHeading
           ctaButton {
             target
             title
             url
           }
         }
         ... on WpPage_Layouts_Layouts_WorkWithUs {
            fieldGroupName
            sectionHeading
            useGlobal
            highlights {
              cta {
                target
                title
                url
              }
              fieldGroupName
              heading
              shortDescription
            }
          }
          ... on WpPage_Layouts_Layouts_Markets {
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_ThreeColumnList {
             fieldGroupName
             heading
             headingCta {
              target
              title
              url
             }
             shortDescription
             lists {
               listHeading
               listItem {
                 listItemText
               }
               listLink {
                  target
                  title
                  url
               }
             }

             theme

          }
          ... on WpPage_Layouts_Layouts_LearnPlan {
            fieldGroupName
            sectionHeading
            useGlobal
            highlights {
              heading
              link
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
              label
            }
            imageLink
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }

          }
          ... on WpPage_Layouts_Layouts_Capabilities {
            fieldGroupName
            sectionHeading
            callToAction {
              target
              title
              url
            }
            tabs {
              heading
              shortDescription
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_Resources {
            fieldGroupName
            sectionHeading
            layoutType
            resourceList {
             ... on WpPost {
               id
               title
               uri
               resources {
                 callToActionLabel
                 excerpt
                 type
                 whitepaper
                 featuredImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
               }
             }
           }
          }
          ... on WpPage_Layouts_Layouts_ThreeColumnContent {
            fieldGroupName
            sectionHeading
            column {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              heading
              shortDescription
              link {
                target
                title
                url
              }
            }
          }
          ... on WpPage_Layouts_Layouts_SliderMarkets {
             fieldGroupName
             heading
             markets {
               ... on WpMarket {
                 id
                 slug
                 title
                 uri
                 markets {
                   featuredImage {
                     altText
                     localFile {
                       childImageSharp {
                         gatsbyImageData(width: 720, height: 540)
                       }
                     }
                   }
                 }
               }
             }
           }
           ... on WpPage_Layouts_Layouts_ProductFeed {
            fieldGroupName
          }
         ... on WpPage_Layouts_Layouts_SubNavigation {
           fieldGroupName
           theme
           navigationItems {
             link {
               target
               url
               title
             }
           }
         }
         ... on WpPage_Layouts_Layouts_Stats {
           fieldGroupName
           sectionHeading
           stats {
             footnote
             number
             shortDescription
           }
         }
         ... on WpPage_Layouts_Layouts_ContentWLogos {
           fieldGroupName
           heading
           listItems {
             listItem
           }
           logos {
             logo {
               altText
               localFile {
                 childImageSharp {
                   gatsbyImageData
                 }
               }
             }
           }
           shortDescription
         }
         ... on WpPage_Layouts_Layouts_ContentBenefits {
           fieldGroupName
           sectionHeading
           theme
           headerImage {
             altText
             localFile {
               childImageSharp {
                 gatsbyImageData(width: 932, height: 480)
               }
             }
           }
           contentGrid {
             heading
             shortDescription
           }
         }
         ... on WpPage_Layouts_Layouts_QuoteLarge {
           author
           location
           quote
           fieldGroupName
         }
         ... on WpPage_Layouts_Layouts_FormRegistration {
            fieldGroupName
            formHeading
            sectionHeading
            formId
          }
         ... on WpPage_Layouts_Layouts_ProjectGallery {
           fieldGroupName
           sectionHeading
           projectGallery {
              ... on WpProject {
                id
                title
                uri
                acfProjects {
                  featuredImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  gallery {
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                    location
                    projectDetails {
                      details
                      sectionHeading
                   }
                }
                projectTypes {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
         }
         ... on WpPage_Layouts_Layouts_FormContact {
            contactEmail
            contactInfo
            fieldGroupName
            heading
            listItems {
              listItem
            }
            formId
          }
          ... on WpPage_Layouts_Layouts_ProductDetails {
            fieldGroupName
            productHeading
           accordionHeading
           productAccordion {
            prodImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            accordionTitle
            accordionContent
           }
           decreaseTopPadding
          }
          ... on WpPage_Layouts_Layouts_ContentTabs {
            fieldGroupName
            sectionHeading
            tabs {
              callToAction {
                target
                title
                url
              }
              heading
              listItems {
                listItem
              }
              shortDescription
              tabName
              tabImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 480, width: 932)
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_ResourceFeatured {
            fieldGroupName
            resource {
              ... on WpPost {
                id
                title
                uri
                categories {
                  nodes {
                    name
                    slug
                    termTaxonomyId
                    seo {
                      title
                      breadcrumbs {
                        text
                      }
                    }
                  }
                }
                resources {
                  excerpt
                  callToActionLabel
                  type
                  whitepaper
                  pdfShareLink {
                     altText
                     localFile {
                       publicURL
                     }
                   }
                  featuredImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 648, height: 420)
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_SeriesBenefits {
            fieldGroupName
            sectionHeading
            headerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
                   contentGrid {
                    heading
                    shortDescription
             }
             sideList {
                    listItem
          }
          bottomCtaImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          ctaSmallText
          ctaLargeText
          ctaLink {
              target
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_ResourcesAll {
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_SpecTables {
            fieldGroupName
            specTables {
              tabLabel
              specTable
            }
          }
          ... on WpPage_Layouts_Layouts_SeriesAccordions {
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_ProductFilters {
            fieldGroupName
          }
          ... on WpPage_Layouts_Layouts_LeftTextHero {
            fieldGroupName
            sectionHeading
            heading
            showButton
            button {
              target
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_MasonryImages {
            fieldGroupName
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            ctaHeading
            ctaContent
            layoutType
          }
          ... on WpPage_Layouts_Layouts_PartnerIcons {
            fieldGroupName
            icons {
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            headline
          }
          ... on WpPage_Layouts_Layouts_FullWidthSplitContent {
            fieldGroupName
            preHeadline
            leftContent
            rightContentBold
            rightContent
            button{
              target
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_TeamGrid {
            fieldGroupName

          }

          ... on WpPage_Layouts_Layouts_FullwidthCta{
            fieldGroupName
            bottomCtaImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          ctaSmallText
          ctaLargeText
          ctaLink {
              target
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_FullWidthImage{
            fieldGroupName
            image{
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    width: 1920
                    quality: 80
                    placeholder: BLURRED
                    height: 1280
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                    outputPixelDensities: [1,2]
                  )
                }
              }
            }
            hoverImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_FullWidthAccordions{
            fieldGroupName
            accordion{
              accordionHeading
              accordionContent
            }
          }
          ... on WpPage_Layouts_Layouts_FullWidthText{
            fieldGroupName
            pageContent
            theme
          }
          ... on WpPage_Layouts_Layouts_WistiaChannel{
            fieldGroupName
            channelId
          }
          ... on WpPage_Layouts_Layouts_PatentsGrid{
            fieldGroupName
            heading
            subHeading
            beforeAndAfter {
              beforeImage {
                localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              }
              afterImage {
                localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              }
              patentNumber
              asSeen
            }
            patentListLeft
            patentListRight
          }
          ... on WpPage_Layouts_Layouts_TwoColumnContent{
            fieldGroupName
            column{
              image{
                localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              }
              heading
              content
            }
          }
          ... on WpPage_Layouts_Layouts_MasonryGallery {
           fieldGroupName
           sectionHeading
           projects {
             ... on WpProject {
               title
               uri
               acfProjects {
                 location
                 featuredImage {
                   altText
                   localFile {
                     childImageSharp {
                       gatsbyImageData
                     }
                   }
                 }
                 projectDetails {
                  details
                  sectionHeading
                }
                 gallery {
                   fieldGroupName
                   image {
                     altText
                     localFile {
                       childImageSharp {
                         gatsbyImageData
                       }
                     }
                   }
                 }
               }
             }
           }
         }
         ... on WpPage_Layouts_Layouts_TwoColumnTextCta{
            fieldGroupName
            leftHeading
            rightContent
          }
          ... on WpPage_Layouts_Layouts_FormSupport{
            supportEmail
            supportInfo
            fieldGroupName
            heading
            listItems {
              listItem
            }
            formId
          }
          ... on WpPage_Layouts_Layouts_Instructions{
            fieldGroupName
            heading
            steps {
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
              heading
              content
            }
            cta {
              target
              title
              url
            }
            showVideo
            videoId
          }
          ... on WpPage_Layouts_Layouts_SeriesList {
            fieldGroupName
            heading
            cta {
              target
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_MediaFeature {
            fieldGroupName
            heading
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    width: 640
                    quality: 80
                    placeholder: BLURRED
                    outputPixelDensities: [1,2]
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH                    
                  )
                }
              }
            }
          }
          ... on WpPage_Layouts_Layouts_SalesforceRma {
            fieldGroupName
            heading
            content
            contactEmail
          }
          ... on WpPage_Layouts_Layouts_SalesforceSupport {
            fieldGroupName
            heading
            content
            contactEmail
          }
          ... on WpPage_Layouts_Layouts_Patents {
            fieldGroupName
            getStartedText
            patentNamesTitle
            patentNumbersTitle
          }
        }
      }
    }
  }
`

const WpPage = ({  data: {
    wpPage: { title, content, layouts, id, slug, seo  },
  }}) => {

  return (

    <Layout>

      {seo
        &&
        <Seo title={seo.title} description={seo.metaDesc} seo={seo} />
      }

      {layouts.layouts && layouts.layouts.map(( layout, index ) => {
        if(layout.fieldGroupName === "Page_Layouts_Layouts_Hero") {
          return ( <Hero content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_SkipHero") {
          return ( <SkipHero content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_Quote") {
          return (<Quote content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_FeaturedClients") {
          return ( <FeaturedClients content={layout} index={index}  key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_WorkWithUs") {
          return ( <WorkWithUs content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_SliderMarkets") {
          return ( <SliderMarkets content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_Capabilities") {
          return ( <Capabilities content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_Resources") {
          return ( <Resources content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_LearnPlan") {
          return ( <PlanLearn content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ThreeColumnContent") {
          return ( <ThreeColumn content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_Markets") {
          return ( <Markets key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ThreeColumnList") {
          return ( <ThreeColumnList content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_ProductFeed") {
          return ( <ProductFeed key={index} index={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_PageMenu") {
          return ( <PageMenu content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_FaqAccordion") {
          return ( <FaqAccordion content={layout} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ProductDetails") {
          return ( <ProductDetails content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_SeriesBenefits") {
          return ( <SeriesBenefits content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_SubNavigation") {
          return ( <SubNavigation content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ContentWLogos") {
          return ( <ContentWithLogos content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ContentBenefits") {
          return ( <ContentBenefits content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_Stats") {
          return ( <Stats content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ProjectGallery") {
          return ( <ProjectGallery content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_QuoteLarge") {
          return ( <QuoteLarge content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_FormRegistration") {
          return ( <FormRegistration content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_FormContact") {
          return ( <FormContact content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_SpecTables") {
          return ( <SpecTables content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_SeriesAccordions") {
          return ( <ProductSeriesAccordions key={index} index={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ContentTabs") {
          return ( <ContentTabs content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ResourcesAll") {
          return ( <AllResources content={layout} index={index} key={index} id={slug} page={id}/> )
        } else if (layout.fieldGroupName === "Page_Layouts_Layouts_ResourceFeatured") {
          return ( <ResourceFeatured content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_ProductFilters") {
          return ( <ProductsFilter key={index} index={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_LeftTextHero") {
          return ( <LeftTextHero content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_MasonryImages") {
          return ( <MasonryImages content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_PartnerIcons") {
          return ( <PartnerIcons content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_FullWidthSplitContent") {
          return ( <SplitContent content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_TeamGrid") {
          return ( <TeamGrid content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_FullwidthCta") {
          return ( <BottomCta content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_FullWidthImage") {
          return ( <FullWidthImage content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_FullWidthAccordions") {
          return ( <CareersAccordions content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_FullWidthText") {
          return ( <FullWidthText content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_WistiaChannel") {
          return ( <WistiaChannelModule content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_PatentsGrid") {
          return ( <PatentGrid content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_TwoColumnContent") {
          return ( <TwoColumnContent content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_MasonryGallery") {
          return ( <MasonryGallery content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_TwoColumnTextCta") {
          return ( <SplitContentCta content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_FormSupport") {
          return ( <FormSupport content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_Instructions") {
          return ( <Instructions content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_SeriesList") {
          return ( <SeriesList content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_MediaFeature") {
          return ( <MediaFeature content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_SalesforceRma") {
          return ( <SalesforceRma content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_SalesforceSupport") {
          return ( <SalesforceSupport content={layout} index={index} key={index} id={slug} page={id}/> )
        }else if (layout.fieldGroupName === "Page_Layouts_Layouts_Patents") {
          return ( <Patents content={layout} index={index} key={index} id={slug} page={id}/> )
        }
      })}

    </Layout>

  )
}

export default WpPage

import * as React from "react"
import { useState } from 'react';
import {Button, Collapse, Tabs, Tab} from 'react-bootstrap'
import "../../styles/spec-tables.css"

const SpecTables = (props) => {
    const [open, setOpen] = useState(false);
    const [isOff, setIsOff] = useState(true);

    return (
        <section class="spec-table" id={"sec-" + props.index}>
            <div className="wrapper-lg block-vw">
            <Tabs  id="uncontrolled-tab-example" className="mb-3">
            {props.content.specTables &&
              props.content.specTables.map((table, index) => (
                    <Tab eventKey={index} title={table.tabLabel} className="margin-top--72">
                         <Collapse in={open}>
                            <div id="table-collapse-text" aria-expanded={false}>
                                <div className="table-responsive"  dangerouslySetInnerHTML={{ __html: table.specTable }}></div>
                            </div>
                        </Collapse>

                    </Tab>
                ))}
                 </Tabs>
            </div>
        </section>
        )
}
export default SpecTables

import * as React from "react"
import { GatsbyImage} from "gatsby-plugin-image"
import { Link } from "gatsby"
import "../../styles/bottom-cta.css"

function bottomCta(cta) {
    if(cta) {
      return <span href={cta.url} className="btn btn-small-arrow btn-outline color-white" target={cta.target}>{cta.title}</span>
    }
  }

  const BottomCta = (props) => {
    return (
        <section className="fw-cta bg-black padding-bottom-160" id={"sec-" + props.index}>
            <div className="wrapper wrapper-lg ">
                <Link 
                    href={props.content?.ctaLink?.url || null}
                    target={props.content?.ctaLink?.target || null}
                    className="benefits-bottom-cta d-md-flex align-items-center margin-top--160 bg-off-black justify-content-between ">
                    <div class="col-md-2">
                        {props.content.bottomCtaImage &&
                        <figure>
                            <GatsbyImage
                                image={props.content.bottomCtaImage.localFile.childImageSharp.gatsbyImageData}
                                alt={props.content.bottomCtaImage.altText}

                            />
                        </figure>  
                        }
                    </div>
                    <div class="col-md-5">
                        <div className="font-16 color-white-50 ">{props.content.ctaSmallText}</div>
                        <div className="font-28 color-white margin-top--4">{props.content.ctaLargeText}</div>
                    </div>
                    <div className="col-md-2 ">
                        {bottomCta(props.content.ctaLink)}
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default BottomCta

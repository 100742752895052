import * as React from "react"

import { GatsbyImage } from "gatsby-plugin-image"


import "../../styles/media-feature.css"


  const MediaFeature = (props) => {

    return (

        <section className="media-feature bg-black">
            <div className="wrapper-lg padding-bottom-112 padding-top-112">
                <div className="d-flex align-items-center justify-content-between media-feature-cols">
                    <div class="col-md-4 relative media-feature-image" data-sal="slide-right" data-sal-delay="200">
                    {props.content.image &&
                        <GatsbyImage
                            image={props.content.image.localFile.childImageSharp.gatsbyImageData}
                            alt={props.content.image.altText}
                            style={{ width: '100%' }}
                        />
                    }
                    </div>
                    <div class="col-md-7">
                        <h2 className="media-feature-heading color-blue" data-sal="slide-up" data-sal-delay="400">{props.content.heading}</h2>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MediaFeature

import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import "../../styles/resources.css"
import { useLocation } from '@reach/router';
let hasRan = false;
const AllResources = (props) => {
  const [activeType, setActiveType] = useState(null)
  const [pageNumber, setPageNumber] = useState(1);

  const data = useStaticQuery(graphql`
    query posts {
      allWpPost(sort: {order: DESC, fields: date}) {
        nodes {
          title
          uri
          resources {
            type
            whitepaper
            excerpt
            callToActionLabel
            pdfShareLink {
               altText
               localFile {
                 publicURL
               }
             }
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 192, width: 312)
                }
              }
            }
          }
          categories {
            nodes {
              name
              slug
              termTaxonomyId
              seo {
                title
                breadcrumbs {
                  text
                }
              }
            }
          }
        }
      }
      allWpCategory(sort: {order: ASC, fields: name}) {
        nodes {
          id
          name
          count
          slug
        }
      }
    }
  `)


  function linkLabel(resource) {
    if(resource.categories.nodes) {
      let label = resource.categories.nodes.slice(-1).pop().seo.breadcrumbs.slice(-1).pop().text
      if(label === 'Blog')  {
        return <span className="link-arrow color-white">View Blog</span>
      } else {
        return <span className="link-arrow color-white">View {label}</span>
      }
    } else {
      return <span className="link-arrow color-white">View Blog</span>
    }

  }

  function linkTarget(resource) {
    // if(resource.categories.nodes) {
    //   let label = resource.categories.nodes.slice(-1).pop().seo.breadcrumbs.slice(-1).pop().text
    //   if(label === 'Whitepaper') {
    //     return '_blank';
    //   }
    // }

    return '';
  }

  function linkUrl(resource) {
    // if(resource.categories.nodes) {
    //   let label = resource.categories.nodes.slice(-1).pop().seo.breadcrumbs.slice(-1).pop().text
    //   if(label === 'Whitepaper') {
    //     if(resource.resources.pdfShareLink) {
    //       return resource.resources.pdfShareLink.localFile.publicURL
    //     }
    //   }
    // }

    return resource.uri
  }

  const location = useLocation();
  if(!hasRan) {
    if(location.hash) {
      setActiveType(location.hash.replace("#", ""));
      hasRan = true;
    }
  }

  const handleType = typeName => {
    setPageNumber(1);
    setActiveType(typeName)
  }

  /** Load More **/
  let filtered = [];
  let pagination = [];
  const resources = data.allWpPost.nodes.reduce((result, resource) => {
    const types = resource.categories.nodes.map(type => type.slug)
    if ((activeType === null || types.includes(activeType)) ) {
      filtered.push(resource);
    }
  }, 0);

  pagination = paginator(filtered, pageNumber, 9)

  function paginator(items, current_page, per_page_items) {
    let per_page = per_page_items * pageNumber;
    let paginatedItems = items.slice(0).slice(0, per_page);
    let total_pages = Math.ceil(items.length / per_page_items);
    console.log(total_pages);
    return {
     total_pages: total_pages,
     data: paginatedItems
    };
  }
  /** End Load More **/

  let gridClass = "col-sm-6 col-md-4 gap-40 margin-top--80";
  let rowClass = "row-40";
  const types = data.allWpCategory.nodes

  return (
    <section className="sec-all-resources block-vw--btm bg-black-true" id={"sec-" + props.index}>
    <div class="project-filtering resource-filtering new-scrollbar-x">
      <div className="wrapper wrapper-md">
        <div className="types d-flex flex-row align-items-center">
          <button
            className={`color-white btn-link type-link ${
                  activeType === null ? "type-active" : "type-inactive"
                }`}
                onClick={() => handleType(null)}
                >
                View all
          </button>
          {types &&
                types.map(type => {
                  if(type.name !== 'Uncategorized') {
                    return (
                      <button
                        className={`color-white btn-link type-link ${
                          activeType === type.slug ? "type-active" : "type-inactive"
                        }`}
                        onClick={() => handleType(type.slug)}
                        id={type.slug}
                      >
                        {type.name}
                      </button>
                    )
                  }
                }
                )}
          </div>
      </div>
    </div>
      <div className="wrapper wrapper-md" >
        <div className={"d-sm-flex flex-wrap " + rowClass} >
          {pagination.data &&
            pagination.data.map((resource, index) => (
              <div className={'color-white' + gridClass }  data-index={index}>
                <a href={linkUrl(resource)} target={linkTarget(resource)} className="color-white">
                  {resource.resources.featuredImage &&
                    <div><GatsbyImage image={resource.resources.featuredImage.localFile.childImageSharp.gatsbyImageData} alt={resource.title} /></div>
                  }
                  <h3 className="headline-5 font-w-500 margin-top--24 color-white">{resource.title}</h3>
                  <div className="margin-top--8 opacity-50 font-20">{resource.resources.excerpt}</div>
                  <div className="margin-top--24">{linkLabel(resource)}</div>
                </a>
              </div>
            ))
          }
        </div>

        {pageNumber < pagination.total_pages
          &&
          <div className="d-flex justify-content-center margin-top--96">
            <button
              className="btn btn-white"
              onClick={() => setPageNumber(pageNumber + 1)}
              >
              Load More
            </button>
          </div>
        }
      </div>
    </section>
  )
}


export default AllResources

import * as React from "react"
import { GatsbyImage} from "gatsby-plugin-image"


import '../../styles/two-column-content.css'

 const TwoColumnContent = (props) => {


  return (

    <section className="sec-two-col bg-black">
            <div className="wrapper wrapper-md block-vw">
                <div className="d-flex row-70 flex-wrap">

                    {props.content.column
                      &&
                      props.content.column.map((column) => (
                        <div className="col-md-6 gap-70 two-col-content">
                            {column.image &&
                            <div className="col-card-top d-flex flex-column justify-content-end">
                                <GatsbyImage
                                        image={column.image.localFile.childImageSharp.gatsbyImageData}
                                        alt={column.image.altText}
                                />
                            </div>
                            }
                            <div className="col-card-item margin-top--50">
                             <div className="font-20 ">{column.heading}</div>
                            </div>
                            <div className="col-card-body margin-top--16">
                                <div className="font-20 color-white-50 brand-content">{column.content}</div>
                            </div>

                        </div>
                    ))}
                </div>

            </div>

    </section>
  );
};


export default TwoColumnContent

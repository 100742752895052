import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "../../styles/masonry-images.css"

const MasonryImages = (props) => {

  let gridClass = "d-md-flex justify-content-end row";

  if(props.content.layoutType === 'verticleLeft') {
    gridClass = 'd-md-flex justify-content-xl-end row flex-row-reverse';
  }

  return (
    <section className="masonry-images block-vw bg-black" >
      {props.content.images &&
      <div className="wrapper-fluid">
        <div className={ gridClass }>
        {props.content.images.map((images, index) => (
          <div className="col-md-auto" data-sal="slide-up" data-sal-delay="200" id={"sec-" + index}>
            <div className="image-wrap">
              <GatsbyImage image={images.image.localFile.childImageSharp.gatsbyImageData}  alt={images.image.altText} />
            </div>
          </div>
          ))}
          <div className="col-md-4" data-sal="slide-up" data-sal-delay="200">
              <div className="color-white font-20 masonry-content">{props.content.ctaHeading}</div>
              <div className="color-white-50 font-20 masonry-content margin-top--32 ">{props.content.ctaContent}</div>
          </div>
        </div>
      </div>
      }
    </section>
  )
}

export default MasonryImages

import * as React from "react"
import "../../styles/full-width-text.css"
import "../../styles/post.css"

let bgClass = "";

const FullWidthText = (props) => {
    if(props.content.theme === "dark") {
        bgClass = "bg-black"
    }
    return (
        <section className={"sec-full-width-text " + bgClass}>
            <div className="post-wrapper">
                <div className="wrapper wrapper-md padding-bottom-240 padding-top-112">
                    <div className="d-flex post-content">
                        <div className="col-md-12" dangerouslySetInnerHTML= {{ __html: props.content.pageContent }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FullWidthText

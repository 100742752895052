import * as React from "react"
import Helmet from "react-helmet"

const WistiaChannelModule = (props) => {


  return (

    <section className={`wistia-embed`}>
      <Helmet bodyAttributes={{
        class: 'media-center'
      }}>
      <script src="https://fast.wistia.com/assets/external/channel.js" async></script>
      </Helmet>
      <div className="wistia_channel wistia_async_t4844zovlb mode=inline" style={{
        'min-height': '100vh',
        'position': 'relative',
        'width': '100%'
      }} />
    </section>
  );
};


export default WistiaChannelModule

import * as React from "react"
import { GatsbyImage} from "gatsby-plugin-image"
import Vimeo from "@u-wave/react-vimeo"

import "../../styles/instructions.css"


const Instructions = (props) => {

  return (
    <section className="sec-instructions bg-black instructions block-vw" id="instructions">
       <header className="wrapper wrapper-sm ">
        <div className="quote-line" data-sal="fade-in"></div>
        <div className="quote-line-30-percent font-16" data-sal="slide-right" data-sal-delay="0">{props.content.heading}</div>
      </header>
      <div className="wrapper wrapper-sm margin-top--72">
        <div className="steps">
          {props.content.steps &&
            props.content.steps.map((step) => {
              return <div className="step">
                <div className="step-heading">{step.heading}</div>
                <div className="step-content">{step.content}</div>
              </div>
            }
          )}
        </div>

        {props.content.showVideo &&
          <div class="relative margin-top--72">
            <Vimeo
              loop={false}
              responsive
              video={props.content.videoId}
              autoplay={false}
              autopause={true}
              className="instructions-video"
              showByline={false}
              muted={false}
              background={false}
            />
          </div>
        }

        {props.content.cta &&
          <div className="margin-top--72">
          <a href={props.content.cta.url} className="btn btn-white" target={props.content.cta.target}>{props.content.cta.title}</a>
          </div>
        }
        
      </div>
    </section>
  )
}

export default Instructions

import * as React from "react"
import '../../styles/patents.css'
import { useStaticQuery, graphql } from "gatsby"
import { useState, useEffect } from 'react'

 const Patents = (props) => {

  const patentsNameTitle = props.content.patentNamesTitle || "Patent Name"
  const patentsNumberTitle = props.content.patentNumbersTitle || "Patent Number"

  const data = useStaticQuery(graphql`
    query patentCategoriesAndPatents {
      allWpPatent {
        nodes {
          title
          patentOptions {
            patentName
            patentDescription
          }
          patentCategories {
            nodes {
              slug
            }
          }
        }
      }
      allWpPatentCategory {
        nodes {
          slug
          name
          description
        }
      }
    }
  `)

  const allPatents = data.allWpPatent.nodes

  // sort patents alphabetically by patent name
  const sortedPatents = [...allPatents].sort((a, b) => (a.patentOptions.patentName > b.patentOptions.patentName) ? 1 : -1)

  const [patents, setPatents] = useState(sortedPatents || [])

  const allCategories = data.allWpPatentCategory.nodes
  const [activeCategory, setActiveCategory] = useState(null)

  const [categorySelecting, setCategorySelecting] = useState(false)
  const [categorySelected, setCategorySelected] = useState(false)
  const [categoryName, setCategoryName] = useState('Filter by Product Family')
  const [categoryDescription, setCategoryDescription] = useState('')
  
  function toggleSelecting() {
    if(categorySelecting) {
      setCategorySelecting(false)
    } else {      
      setCategorySelecting(true)
    }
  }


  useEffect(() => {
    if (activeCategory === null) {
      setPatents(null)
    } else {
      setPatents(sortedPatents.filter(patent => patent.patentCategories.nodes.some(category => category.slug === activeCategory)))
    }
  }, [activeCategory])

  return (
    <section className="sec-patents-grid bg-black">
      <div className="padding-bottom-240">
          <div className="wrapper wrapper-md d-flex relative padding-top-71">
            <h2>Browse Patents</h2>
          </div>
          <div className="wrapper wrapper-md d-flex relative padding-top-71">
            <div className="top-border"></div>
            <div className="patents-wrap">
              <div className="category-selector">
              <div className={`category-selector__label ${categorySelecting ? 'category-selector__label--selecting' : ''}`} onClick={() => toggleSelecting(true)}>{categoryName}</div>
                {categorySelecting && (
                  <div className="category-selector__select">
                    {allCategories.map((category) => (
                      <span 
                        className="category-selector__option" 
                        onClick={() => {
                          setCategoryName(category.name)
                          setCategorySelected(true)
                          setCategorySelecting(false)
                          setActiveCategory(category.slug)
                          setCategoryDescription(category.description)
                        }}
                        key={`patent-category-${category.slug}`}
                      >
                        {category.name}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              {patents && (
                <div className="patents-list">
                  {categoryDescription && (
                    <div className="patents-description">
                      <span>{categoryDescription}</span>
                    </div>
                  )}
                  <div className="patents-list-row">
                    <div className="patents-list-col">
                      <div className="font-18 color-white">{patentsNameTitle}</div>
                    </div>
                    <div className="patents-list-col patents-list-col--right">
                      <div className="font-18 color-white">{patentsNumberTitle}</div>
                    </div>
                  </div>
                  {patents.map((patent) => (
                    <div 
                      className="patents-list-row patents-list-item"
                      key={`patent-${patent.title}`}
                      >
                      <div className="patents-list-col">
                        <div className="font-18 color-white-50">{patent.patentOptions.patentName}</div>
                      </div>
                      <div className="patents-list-col patents-list-col--right">
                        <div className="font-18 color-white-50">{patent.title}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!patents && (
                <div className="patents-preview">
                  <span>{props.content.getStartedText}</span>
                </div>
              )}
            </div>
          </div>
      </div>
    </section>
  );
};


export default Patents
